<template>
  <div class="wrap" v-if="!configPageVisible">
    <p class="title">引导页管理</p>
    <!-- 操作 -->
    <div class="action">
      <a-button type="primary" size="small" @click="toggleConfigPage">引导页配置</a-button>
      <!-- <a-button type="primary" size="small" @click="showAddEditModal">添加引导页</a-button> -->
    </div>
    <!-- 表格 -->
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{ pageSize: pageSize, current: curPage, total: total }"
      :row-key="record => record.id"
      @change="handlePageChange"
    >
      <template #idx="row">
        {{ (curPage - 1) * pageSize + row.index + 1 }}
      </template>
      <template #picture="row">
        <img class="avatar" :src="`data:image/jpg;base64,${row.record.picture}`" alt="封面" />
      </template>
      <template #action="row">
        <div class="action">
          <span class="edit" @click="showAddEditModal(row)">编辑</span>
          <!-- <span class="del" @click="del(row)">删除</span> -->
        </div>
      </template>
    </a-table>
  </div>
  <guide-page-config v-else @saveSuccess="toggleConfigPage"></guide-page-config>
  <add-edit-guide-modal ref="AddEditGuideRef" @addOrUpdateSuccess="search"></add-edit-guide-modal>
</template>

<script>
import { defineComponent, ref, toRefs, createVNode, reactive, onMounted } from 'vue';
import api from '@/services';
import { Modal, message } from 'ant-design-vue';
import AddEditGuideModal from './AddEditGuideModal';
import GuidePageConfig from './GuidePageConfig';

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center',
    width: 80,
    slots: {
      customRender: 'idx'
    }
  },
  {
    title: '图片',
    dataIndex: 'picture',
    align: 'center',
    slots: {
      customRender: 'picture'
    }
  },
  {
    title: '名称',
    dataIndex: 'name',
    align: 'center',
    ellipsis: true
  },

  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 150,
    align: 'center',
    slots: {
      customRender: 'action'
    }
  }
];
export default defineComponent({
  components: {
    AddEditGuideModal,
    GuidePageConfig
  },
  setup() {
    onMounted(() => {
      getGuidePageList();
    });

    const AddEditGuideRef = ref();
    const isEdit = ref(false);

    const visible = ref(false);

    const configPageVisible = ref(false);

    // table 相关变量
    const tableState = reactive({
      pageSize: 5,
      curPage: 1,
      total: 0,
      dataSource: [],
      columns
    });

    // 条件筛选表单
    const formState = reactive({
      taskName: '',
      flyer: '',
      rangeDate: []
    });

    // 获取引导页列表 table data
    const getGuidePageList = async () => {
      const params = {
        curPage: tableState.curPage,
        pageSize: tableState.pageSize
      };
      delete params.rangeDate;

      const { data, success } = await api.getGuidePageList(params);
      if (success) {
        tableState.dataSource = data.records;
        tableState.total = data.total;
      }
    };

    // 显示 新增 / 编辑 弹窗
    const showAddEditModal = row => {
      let rowData;
      if (row.record) {
        isEdit.value = true;
        rowData = row.record;
      } else {
        isEdit.value = false;
      }
      AddEditGuideRef.value.showModal(isEdit.value, rowData);
    };

    const toggleConfigPage = () => {
      configPageVisible.value = !configPageVisible.value;
    };

    // 删除
    const del = row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          `确认要删除吗？`
        ),

        async onOk() {
          let { success } = await api.delGuidePageById(row.record.id);
          if (success) {
            message.success('删除成功！');
            search();
          }
        }
      });
    };

    const handleCancel = () => {
      visible.value = false;
    };

    // 查看详情
    const showDetailModal = () => {};

    const handlePageChange = page => {
      tableState.curPage = page.current;
      getGuidePageList();
    };

    const search = () => {
      tableState.curPage = 1;
      getGuidePageList();
    };

    return {
      toggleConfigPage,
      AddEditGuideRef,
      showAddEditModal,
      isEdit,
      visible,
      configPageVisible,
      ...toRefs(formState),
      getGuidePageList,
      handlePageChange,
      search,
      del,
      handleCancel,
      showDetailModal,
      ...toRefs(tableState)
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .action {
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      margin-right: 20px;
    }
  }
  :deep(.ant-form) {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    .btn-group {
      flex: 1;
      .ant-btn {
        margin-left: 20px;
      }
    }
  }
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .action > span {
        margin-right: 14px;
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }

      .action {
        display: flex;
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          background-color: #007fff;
        }
        .del {
          background-color: #d71345;
        }
      }
    }
  }
}
</style>
